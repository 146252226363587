<template>
    <v-row>
        <template v-for="n in 12">
            <v-col cols="12" md="6" lg="4" xl="3" :key="n">
                <v-skeleton-loader
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    name: "watch-video-loading-skeleton-loaders",
};
</script>
