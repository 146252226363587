<template>
    <div>
        <v-container
            id="home"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="auto" cols="12">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Watch</v-toolbar-title
                        >
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort by"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 15rem"
                            clearable
                            @click:clear="content.options.uploadSort = null"
                            v-model="content.options.uploadSort"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 8rem"
                            clearable
                            @click:clear="content.options.perPage = 10"
                            v-model="content.options.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="auto" cols="12">
                        <v-btn
                            class="border-radius-25 px-5 mx-1"
                            @click="openVideoFormDialog('add')"
                            depressed
                            rounded
                            dark
                            color="accent"
                            >Add Video</v-btn
                        >
                    </v-col>
                    <v-col md="auto" cols="12" class="d-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by playlist"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="playlists"
                            item-value="name"
                            item-text="name"
                            small-chips
                            multiple
                            style="max-width: 16rem"
                            v-model="content.options.playlists"
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip
                                    class="white--text"
                                    color="secondary"
                                    small
                                    :key="JSON.stringify(item)"
                                    >{{ item.name }}</v-chip
                                >
                            </template>
                        </v-select>
                        <div class="mx-1"></div>
                        <!-- <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by video"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="videos"
                            item-value="title"
                            item-text="title"
                            small-chips
                            multiple
                            style="max-width: 16rem"
                            v-model="content.options.videoSeries"
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip
                                    class="white--text"
                                    color="secondary"
                                    small
                                    :key="JSON.stringify(item)"
                                    >{{ item.title }}</v-chip
                                >
                            </template>
                        </v-select> -->
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="width: 20rem"
                            readonly
                            clearable
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            @click:clear="dateRangeDescription = null"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        lg="4"
                        xl="3"
                        v-for="(video, index) in content.items"
                        :key="index"
                    >
                        <watch-video-preview-card
                            :videoID="video.id"
                            :title="video.title"
                            :schedule="video.schedule_at"
                            :youtube-link="video.youtube_link"
                            :featured-image="video.featured_image"
                            :language="video.language"
                            :category="video.video_category"
                            @onViewInformation="
                                openVideoInformationDialog(video)
                            "
                            @onDeleteSuccess="resetContent"
                            :key="index"
                        ></watch-video-preview-card>
                    </v-col>
                </v-row>

                <watch-video-loading-skeleton-loaders
                    v-if="isGetVideosStart"
                ></watch-video-loading-skeleton-loaders>
            </v-container>

            <watch-video-form-dialog
                :show.sync="videoForm.show"
                :action="videoForm.action"
                :data="videoForm.data"
                @onSuccess="resetContent"
                @close="videoForm = { ...videoForm, show: false, data: null }"
            ></watch-video-form-dialog>

            <watch-video-information-dialog
                :show.sync="videoInformationDialog.show"
                :data="videoInformationDialog.data"
                @close="
                    videoInformationDialog = {
                        ...videoInformationDialog,
                        show: false,
                        data: null,
                    }
                "
                @onOpenUpdateForm="openVideoFormDialog('edit')"
            ></watch-video-information-dialog>
        </v-container>

        <base-infinite-scroll
            :action="getVideos"
            :identifier="infiniteId"
        ></base-infinite-scroll>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="content.options.dateFrom"
            :end-date.sync="content.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import {
    GET_LANGUAGES,
    GET_PLAYLISTS,
    GET_VIDEOS,
} from "@/store/modules/selection";
import { WATCH_GET_VIDEOS } from "@/store/modules/watch";
import WatchVideoPreviewCard from "@/components/MainView/Watch/components/VideoPreviewCard";
import WatchVideoInformationDialog from "@/components/MainView/Watch/components/VideoInformationDialog";
import BaseInfiniteScroll from "@/components/Base/BaseInfiniteScroll";
import WatchVideoFormDialog from "@/components/MainView/Watch/components/VideoFormDialog";
import WatchVideoLoadingSkeletonLoaders from "@/components/MainView/Watch/components/VideoLoadingSkeletonLoaders";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";

export default {
    name: "watch",

    components: {
        BaseDateRangeDialog,
        WatchVideoLoadingSkeletonLoaders,
        WatchVideoFormDialog,
        BaseInfiniteScroll,
        WatchVideoInformationDialog,
        WatchVideoPreviewCard,
    },

    data() {
        return {
            content: {
                items: [],
                options: {
                    uploadSort: "desc",
                    playlists: [],
                    videoSeries: [],
                    dateFrom: null,
                    dateTo: null,
                },
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            videoForm: { show: false, action: "", data: null },
            videoInformationDialog: { show: false, data: null },
            isGetVideosStart: false,
            infiniteId: +new Date(),
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        playlists() {
            return this.$store.state.selection.playlists;
        },

        videos() {
            return this.$store.state.selection.videos;
        },
    },

    watch: {
        ["content.options"]: {
            handler() {
                this.content.pagination.page = 1;
                this.resetContent();
            },
            deep: true,
        },

        dateRangeDescription(value) {
            if (!value) {
                this.content.options.dateFrom = null;
                this.content.options.dateTo = null;
            }
        },
    },

    methods: {
        async getVideos($state) { 
            const { uploadSort, playlists, videoSeries, dateFrom, dateTo } =
                this.content.options;
            const { page, perPage } = this.content.pagination;
            const payload = {
                page: page || 1,
                perPage: perPage || 10,
                uploadSort: uploadSort || "desc",
                playlists: playlists || [],
                videoSeries: videoSeries || [],
                dateFrom: dateFrom || null,
                dateTo: dateTo || null,
            };
            this.isGetVideosStart = true;
            const { success, data } = await this.$store.dispatch(
                WATCH_GET_VIDEOS,
                payload
            );
            if (success) {
                const items = data.data;
                if (items.length === this.content.pagination.perPage) {
                    this.content.pagination.total = data.meta.total || 0;
                    this.content.items = [...this.content.items, ...items];
                    this.content.pagination.page += 1;
                    this.isGetVideosStart = false;
                    $state.loaded();
                    return;
                }
                this.content.items = [...this.content.items, ...items];
                this.isGetVideosStart = false;
                $state.complete();
            }
        },

        openVideoFormDialog(action) {
            switch (action) {
                case "add":
                    this.videoForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.videoForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.videoInformationDialog.data
                        ),
                    };
                    break;
                case "delete":
                    break;
                default:
                    this.videoForm = { show: true, action, data: null };
                    break;
            }
        },

        openVideoInformationDialog(data) {
            this.videoInformationDialog = {
                show: true,
                data: Object.assign({}, data),
            };
        },

        async resetContent() {
            console.log("RESETTING CONTENT");
            this.content.items = [];
            this.content.pagination.page = 1;
            this.getVideos();
            this.initiate();
            this.infiniteId += 1;
        },

        async initiate() {
            await this.$store.dispatch(GET_PLAYLISTS);
            await this.$store.dispatch(GET_VIDEOS);
            await this.$store.dispatch(GET_LANGUAGES);
        },
    },

    created() {
        this.getVideos();
        this.initiate();
    },
};
</script>
