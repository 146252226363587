<template>
    <base-dialog width="1069" :is-open.sync="dialog">
        <v-card-title
            class="align-center justify-start"
            style="background-color: #f1eeee"
        >
            <v-btn
                class="border-radius-25 px-5 text-capitalize"
                rounded
                dark
                color="success"
                depressed
                @click="openUpdateVideoForm"
                >Update</v-btn
            >
        </v-card-title>

        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="px-5">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <iframe
                            :src="`https://www.youtube.com/embed/${youtubeID}`"
                            onload="this.width=screen.width;this.height=screen.height;"
                            title="YouTube video player"
                            frameborder="0"
                            style="width: 100%; height: 548px"
                            class="rounded-lg"
                        ></iframe>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div
                                    class="d-flex align-center align-content-center"
                                >
                                    <v-list-item three-line style="width: 100%">
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <span
                                                            class="font-weight-bold success--text"
                                                            style="
                                                                word-wrap: break-word;
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >{{ title }}</span
                                                        >
                                                    </template>
                                                    {{ title }}
                                                </v-tooltip>
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                class="secondary--text caption mb-2"
                                            >
                                                {{
                                                    formattedScheduleDate
                                                }}</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle>
                                                <template
                                                    v-for="(tag, index) in tags"
                                                >
                                                    <v-chip
                                                        class="font-weight-bold secondary mr-2"
                                                        small
                                                        dark
                                                        :key="index"
                                                    >
                                                        {{ tag }}
                                                    </v-chip>
                                                </template>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </v-col>

                            <v-col cols="12" md="6">
                                <div
                                    :class="[
                                        { 'left-border': !!description },
                                        'd-flex fill-height align-center px-5  black--text',
                                    ]"
                                >
                                    {{ description }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </base-dialog>
</template>

<script>
import helperUtility from "@/mixins/helper";
import dateUtility from "@/mixins/date";
import BaseDialog from "@/components/Base/BaseDialog";

export default {
    name: "watch-video-information-dialog",
    components: { BaseDialog },
    props: {
        show: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            dataLocal: Object.assign({}, this.data),
            error: null,
        };
    },

    computed: {
        youtubeID() {
            const youtubeLink = this.data ? this.data.youtube_link : null;
            if (!youtubeLink) {
                return null;
            }

            return helperUtility.getYoutubeIDFromURL(youtubeLink);
        },

        formattedScheduleDate() {
            if (!this.data) return null;
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(this.data.schedule_at)
            );
        },

        tags() {
            if (!this.data) return null;
            const tags = [];
            const category = this.data.video_category;
            if (category) {
                tags.push(category.name);
            }
            const language = this.data.language;
            if (language) {
                tags.push(language.name);
            }

            return tags;
        },

        title() {
            if (!this.data) return null;
            return this.data.title;
        },

        description() {
            if (!this.data) return null;
            return this.data.description;
        },
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(to) {
            if (!to) {
                this.$emit("close");
            }
        },

        data(value) {
            if (value) {
                this.dataLocal = Object.assign({}, value);
            }
        },
    },

    methods: {
        openUpdateVideoForm() {
            this.dialog = false;
            this.$emit("onOpenUpdateForm");
        },
    },
};
</script>

<style lang="scss" scoped>
.left-border {
    border-left: 2px solid rgba(#78756a, 20%);
}
</style>
